.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: 100%;
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 150px;
        margin-bottom: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            max-width: 90%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 10px;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 10px;
            color: #fff;
            font-weight: 400;
        }

        .stack {
            margin-bottom: 10px;
            color: white;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #4169e1 ;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #4169e1 ;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #4169e1 , #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}
@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        width: 100%;
        padding: 20px;
       // box-sizing: border-box;

        h1.page-title {
            margin-left: 20px;
            margin-top: 135px;
            text-align: center;
        }
    
        .image-box {
            height: 200px;
            min-width: 47%;
        }
        .content {
            justify-content: center;
            max-width: 90%;
        }
    }
   
}

@media screen and (max-width: 760px) {
    .container.portfolio-page {
        width: 100%;
        padding: 20px;
      //  box-sizing: border-box;
    
        .image-box {
            height: 200px;
            min-width: 100%;
        }
        .content {
            justify-content: center;
            max-width: 90%;
        }
    }
    
}
